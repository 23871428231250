.selected-btn {
  scale: 70%;
  transition: scale 1s;
  cursor: pointer;
}
.selected-btn:hover {
  color: red;
}
.selected-btn:focus {
  scale: 100%;
  transition: scale 0.5s;
  transition-delay: 0.9s;
}

.selected-btn-mobile {
  scale: 70%;
  transition: scale 1s;
  cursor: pointer;
}
.selected-btn-mobile:hover {
  color: red;
}
.selected-btn-mobile:focus {
  scale: 80%;
  transition: scale 0.5s;
  transition-delay: 0.9s;
}

.ini {
  rotate: 0deg;
  transition: rotate 1s;
}

.vel {
  rotate: 300deg;
  transition: rotate 1s;
}
.con {
  rotate: 60deg;
  transition: rotate 1s;
}
.gen {
  rotate: 120deg;
  transition: rotate 1s;
}
.ele {
  rotate: 180deg;
  transition: rotate 1s;
}
.per {
  rotate: 240deg;
  transition: rotate 1s;
}

.text-box {
  opacity: 0%;
  transition: opacity 1s;
}

.text-box-100 {
  opacity: 100%;
  transition: opacity 1s;
}
