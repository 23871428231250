@media (max-height: 425px) and (orientation: landscape) {
  body {
    height: 100vw;
    transform: rotate(90deg);
  }
}

.loading-screen {
  position: absolute;
  width: 100%;
  height: 90vh;
  background-color: black;
  overflow: hidden;
  z-index: 10;
}

.loading {
  position: relative;
  width: 5%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: white;
}

.container-mobile {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
}

.background-container {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  transform: rotate(-10deg);
}

.background-container-mobile {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 60%;
  height: 60%;
  transform: rotate(-10deg);
}

.background {
  position: absolute;
  top: 0%;
  left: -10%;
  width: 250%;
  height: 150%;
  background-image: url("../imgs/FondoLargo.jpg");
  background-size: cover;
  animation: slide-right-left 15s linear infinite;
  opacity: 0.75;
}

.background-mobile {
  position: absolute;
  top: 10%;
  left: 20%;
  width: 200%;
  height: 100%;
  background-image: url("../imgs/FondoLargo.jpg");
  background-size: cover;
  animation: slide-right-left 15s linear infinite;
  opacity: 0.95;
}

@keyframes slide-right-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-40%);
  }
}

@keyframes logo-beat {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(0.92);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes move-right-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-8%);
  }
}

@keyframes move-left-right {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  20% {
    transform: translateX(0);
    opacity: 1;
  }
  80% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%);
    opacity: 0;
  }
}

.center-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 1;
}

.logo-container {
  position: sticky;
  top: 5%;
  left: 10%;
  width: 100%;
  height: auto;
  z-index: 10;
}

.logo-anim {
  animation: logo-beat 1s linear infinite;
}

.character-container {
  position: absolute;
  top: 50%;
  left: 20%;
  width: 100%;
  height: auto;
  z-index: 4;
}

.balloon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-16%, -130%);
  width: 32%;
  height: auto;
  opacity: 1;
  z-index: 5;
}

.balloon-mobile {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-16%, -130%);
  width: 50%;
  height: auto;
  opacity: 1;
  z-index: 5;
}

.clock {
  position: absolute;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  top: 50%;
  left: 50%;
  width: 30%;
  height: auto;
  color: white;
  font-family: "Orbitron", sans-serif;
  z-index: 5;
  align-items: center;
  justify-content: center;
  transform: translate(-14%, -220%);
}

.clock-mobile {
  position: absolute;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  top: 35%;
  left: 50%;
  width: 50%;
  height: auto;
  color: white;
  z-index: 5;
  font-family: "Orbitron", sans-serif;
  align-items: center;
  justify-content: center;
  transform: translate(-14%, -220%);
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  font-family: "Orbitron", sans-serif;
}

.grid-item-number {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2.8vw;
  min-width: 2em;
  font-family: "Orbitron", sans-serif;
}

.grid-item-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2vw;
  font-family: "Orbitron", sans-serif;
}

.grid-item-number-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 3.8vw;
  min-width: 2em;
  font-family: "Orbitron", sans-serif;
}

.lines-anim {
  animation: move-right-left 3s linear infinite;
}

.extra-info {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font: 2vh;
  z-index: 7;
  margin: 1px;
  padding: 10px;
  font-family: "Orbitron", sans-serif;
}

.extra-info-container {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 20%);
}

.extra-info-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logos-container {
  display: flex;
  flex-direction: column;
  margin-top: 5vw;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
  color: rgb(137, 137, 137);
  margin-bottom: 5vw;
  font-weight: normal;
}

.logos-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  height: 100%;
  width: auto;
  justify-items: center;
}

.logo-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vw;
  padding: 2em;
  z-index: 9;
  margin-bottom: 4em;
}

.logo-item-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12vw;
  padding: 1em;
  z-index: 9;
  margin-bottom: 0em;
}

.btn {
  background-color: #ed205b;
  color: white;
  font-size: 1vw;
  padding: 10px;
  border: 5px;
}

.btn-mobile {
  background-color: #ed205b;
  color: white;
  font-size: 3vw;
  padding: 10px;
  border: 5px;
}

.btn-mobile-t {
  background-color: #ed205b;
  color: white;
  font-size: 2vw;
  padding: 10px;
  border: 5px;
}

.form {
  padding: 10px;
  border: none;
}

.mask-black {
  -webkit-mask-image: url(../imgs/videoMask2.svg);
  -webkit-mask-position: 40% 50%;
  -webkit-mask-repeat: repeat;
  -webkit-mask-size: 140%;

  mask-image: url(../imgs/videoMask2.svg);
  mask-position: 40% 50%;
  mask-repeat: repeat;
  mask-size: 120%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 101vh;
  width: 100vw;
  z-index: 1;
  background-color: black;
}

.mask-pink {
  -webkit-mask-image: url(../imgs/videoMask2.svg);
  -webkit-mask-position: 40% 50%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 110%;

  mask-image: url(../imgs/videoMask2.svg);
  mask-position: 40% 50%;
  mask-repeat: no-repeat;
  mask-size: 110%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 101%;
  width: 101%;
  z-index: 1;
  background-color: #ed205b;
}

.mask-black-mobile {
  -webkit-mask-image: url(../imgs/videoMask2mobile.svg);

  -webkit-mask-repeat: repeat;
  -webkit-mask-size: 100%;

  mask-image: url(../imgs/videoMask2mobile.svg);

  mask-repeat: repeat;
  mask-size: 100%;

  position: absolute;
  top: 0%;
  left: 0%;

  height: 101%;
  width: 101%;
  z-index: 1;
  background-color: black;
}

.mask-pink-mobile {
  -webkit-mask-image: url(../imgs/videoMask2mobile.svg);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 95%;

  mask-image: url(../imgs/videoMask2mobile.svg);
  mask-repeat: no-repeat;
  mask-size: 95%;

  position: absolute;
  top: 0%;
  left: 0%;
  height: 101%;
  width: 101%;
  z-index: 1;
  background-color: #ed205b;
}

.mask-black-mobile-t {
  -webkit-mask-image: url(../imgs/videoMask2mobile.svg);
  -webkit-mask-position: 40% 50%;
  -webkit-mask-repeat: repeat;
  -webkit-mask-size: 120%;

  mask-image: url(../imgs/videoMask2mobile.svg);
  mask-position: 40% 50%;
  mask-repeat: repeat;
  mask-size: 120%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 101vh;
  width: 100vw;
  z-index: 1;
  background-color: black;
}

.mask-pink-mobile-t {
  -webkit-mask-image: url(../imgs/videoMask2mobile.svg);
  -webkit-mask-position: 40% 50%;
  -webkit-mask-repeat: repeat;
  -webkit-mask-size: 110%;

  mask-image: url(../imgs/videoMask2mobile.svg);
  mask-position: 40% 50%;
  mask-repeat: repeat;
  mask-size: 110%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 101%;
  width: 101%;
  z-index: 1;
  background-color: #ed205b;
}

.dots-black {
  background-image: url(../imgs/FondoPuntos.png);
  background-repeat: repeat;
  background-size: cover;
  background-size: 50%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 20%;
}

.baseTxt-mobile {
  background-image: url(../imgs/BaseMobile-01.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  z-index: 0;
}
