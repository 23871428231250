.main {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
}
.video-container {
  position: relative;
  width: 100%;
  height: 80vh;
  color: white;
}

.link-class {
  text-align: center;
  color: red;
  font-family: "Orbitron", sans-serif;
  cursor: pointer;
  text-decoration: none;
}

.video {
  -webkit-mask-image: url(../imgs/videoMask.svg);
  -webkit-mask-position: 40% 40%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 60%;

  mask-image: url(../imgs/videoMask.svg);
  mask-position: 40% 40%;
  mask-repeat: no-repeat;
  mask-size: 60%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90%;
  width: 100%;
  z-index: 1;
}

.video-phone {
  -webkit-mask-image: url(../imgs/videoMask.svg);
  -webkit-mask-position: 40% 40%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100%;

  mask-image: url(../imgs/videoMask.svg);
  mask-position: 40% 40%;
  mask-repeat: no-repeat;
  mask-size: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90%;
  width: 100%;
  z-index: 1;
}

.video-tablet {
  -webkit-mask-image: url(../imgs/videoMask.svg);
  -webkit-mask-position: 40% 40%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100%;

  mask-image: url(../imgs/videoMask.svg);
  mask-position: 40% 40%;
  mask-repeat: no-repeat;
  mask-size: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90%;
  width: 100%;
  z-index: 1;
}
