.download {
  background-image: url('../imgs/bigBgDownload.jpg');
  background-size: 200em;
  animation: slide-right-left 15s linear infinite;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.download-bg-black {
  background-color: black;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.download-black {
  background-image: url('../imgs/bigBgDownloadBlack-01.jpg');
  background-size: 200em;
  animation: slide-right-left 15s linear infinite;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.download-bg {
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.mask-download-black {
  position: absolute;
  background-image: url('../imgs/BrushBack-02.svg');
  background-size: 20em;
  background-repeat: repeat-y;
  left: 0%;
  z-index: 2;
  width: 101%;
  height: 100vh;
}

@keyframes slide-right-left {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}
