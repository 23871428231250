.information {
  position: relative;
  width: 100%;
  height: 85vh;
  overflow: hidden;
}

.info-container {
  position: relative;

  overflow: hidden;
}
