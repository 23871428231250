.btn-navbar {
  padding-left: 1em;
  padding-right: 1em;
  border: none;
  background: none;
  color: white;
  cursor: pointer;
  font-size: small;
  font-family: 'Orbitron', sans-serif;
}

.btn-navbar-mobile {
  border: none;
  background: none;
  color: white;
  cursor: pointer;
  font-size: xx-small;
  font-family: 'Orbitron', sans-serif;
}

.btn-navbar-mobile-t {
  border: none;
  background: none;
  color: white;
  cursor: pointer;
  font-size: x-small;
  font-family: 'Orbitron', sans-serif;
}

.btn-navbar-white {
  padding-left: 1em;
  padding-right: 1em;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  font-size: small;
}

.btn-navbar-white-mobile {
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  font-size:xx-small;
  font-family: 'Orbitron', sans-serif;
}

.btn-navbar-white-mobile-t {
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  font-size:x-small;
  font-family: 'Orbitron', sans-serif;
}

.btn-navbar:hover {
  color: red;
  text-decoration: underline;
  text-underline-offset: 0.5em;
  text-decoration-color: red;
  text-decoration-thickness: 0.2em;
}

.btn-navbar:focus {
  color: red;
  text-decoration: underline;
  text-underline-offset: 0.5em;
  text-decoration-color: red;
  text-decoration-thickness: 0.2em;
}

.btn-navbar-white:hover {
  color: red;
  text-decoration: underline;
  text-underline-offset: 0.5em;
  text-decoration-color: red;
  text-decoration-thickness: 0.2em;
}

.btn-navbar-white:focus {
  color: red;
  text-decoration: underline;
  text-underline-offset: 0.5em;
  text-decoration-color: red;
  text-decoration-thickness: 0.2em;
}

.social-col {
  padding-left: 1em;
  padding-right: 1em;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
}

.social-col-white {
  padding-left: 1em;
  padding-right: 1em;
  border: none;
  background: none;
  cursor: pointer;
  color: black;
}
